<template>
  <pz-form-flujo
    v-model="formulario_validarTelefono['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.validacion_sms_default.title') }}
    </span>
    <span slot="form_subtitle">
      {{ $t('forms_flujo.validacion_sms_default.sub_title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      :model="formulario_validarTelefono"
      status-icon
      :rules="rules_formulario_validarTelefono"
    >
      <el-col
        :xl="{span: 6,offset:9}"
        :lg="{span: 6,offset:9}"
        :md="{span: 6,offset:9}"
        :sm="{span: 24}"
        :xs="{span: 24}"
      >
        <el-form-item
          prop="codigo_verificacion"
          :label="$t('forms_flujo.validacion_sms_default.verification_code')"
          :error="errors.get('codigo_verificacion')"
        >
          <el-input
            v-model="formulario_validarTelefono.codigo_verificacion"
            :type="'text'"
            :placeholder="$t('forms_flujo.validacion_sms_default.verification_code')"
          />
        </el-form-item>
      </el-col>
    </el-form>
    <el-col
      slot="buttons"
      :span="24"
      class="center-block text-center"
    >
      <pz-back-button
        v-if="showBack"
        :back="handleBack"
      />
      <pz-button
        :button-name="$t('forms_flujo.validacion_sms_default.resend_btn')"
        @click="handleResend"
      />
      <pz-captcha-button
        v-if="extraData.withCaptcha"
        v-model="formulario_validarTelefono['captcha']"
        :forward="handleSave"
      />
      <pz-forward-button
        v-else
        v-model="formulario_validarTelefono['captcha']"
        :forward="handleSave"
      />
    </el-col>
  </pz-form-flujo>
</template>

<script>
    import PzForwardButton from "@/commons/forms/Buttons/PzForwardButton";
    import PzBackButton from "@/commons/forms/Buttons/PzBackButton";
    import baseValidacionTelefono from "./baseValidacionTelefono";
    import {required} from '@/commons/utils/Rules';
    import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
    import PzButton from "@/commons/forms/Buttons/PzButton";

    export default {
        name: 'PzValidateBySms',
        components: {PzButton, PzFormFlujo, PzBackButton, PzForwardButton},
        mixins: [baseValidacionTelefono],
        data() {
            return {
                formulario_validarTelefono: {codigo_verificacion: ''},
                rules_formulario_validarTelefono: {
                    codigo_verificacion: [required(''),
                    ],
                }
            }
        },
    }
</script>

