<template>
  <pz-form-flujo
    v-model="formulario_verificar_cliente['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span
      v-show="showForm"
      slot="form_title"
    >
      {{ $t('forms_flujo.verificacion_cliente.title') }}
    </span>

    <el-form
      v-show="showForm"
      slot="formulario"
      :ref="reference"
      :model="formulario_verificar_cliente"
      status-icon
      :rules="rules"
    >
      <el-col
        :xl="{span: 12,offset:6}"
        :lg="{span: 12,offset:6}"
        :md="{span: 12,offset:6}"
        :sm="{span: 24}"
        :xs="{span: 24}"
      >
        <pz-identity-document
          ref="identity"
          v-model="formulario_verificar_cliente.identity"
          prop="identity"
          type="person"
          :type-label="$t('forms_flujo.form1_persona.SP.tipo_doc')"
          :number-label="$t('forms_flujo.form1_persona.SP.identity_doc')"
        />
      </el-col>
      <br>
      <el-col
        :span="24"
        class="center-block text-center"
      >
        <span style="color:#db996c;">
          {{ $t('forms_flujo.pz_simulators_template.warning_bnf') }}
        </span>
      </el-col>
    </el-form>

    <el-col
      v-show="showForm"
      slot="buttons"
      :span="24"
      class="center-block text-center"
    >
      <pz-back-button
        v-if="showBack"
        :back="handleBack"
      />
      <pz-captcha-button
        v-if="extraData.withCaptcha"
        v-model="formulario_verificar_cliente['captcha']"
        :forward="handleSave"
      />
      <pz-forward-button
        v-else
        v-model="formulario_verificar_cliente['captcha']"
        :forward="handleSave"
      />
    </el-col>
  </pz-form-flujo>
</template>

<script>
import PzForwardButton from "@/commons/forms/Buttons/PzForwardButton";
import PzBackButton from "@/commons/forms/Buttons/PzBackButton";
import baseVerificacionCliente from "./baseVerificacionCliente";
import {required, EspacioBlanco} from '@/commons/utils/Rules';
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import {isEmpty} from '@/commons/utils/functions';

export default {
  name: 'PzVerificacionCliente',
  components: {PzFormFlujo, PzBackButton, PzForwardButton, PzIdentityDocument},
  mixins: [baseVerificacionCliente],

  data() {
    return {
      formulario_verificar_cliente: {
        identity: {}
      },
      defaultData: {},
      rules: {
        'identity.document': [required('')],
        'identity.number': [required(''), EspacioBlanco()]
      },
      showForm: false
    }
  },

  mounted() {
    this.checkDocAvailability();
  },

  methods: {
    checkDocAvailability() {
      if (this.defaultData !== null ) {
        if (this.defaultData.hasOwnProperty('tipoDoc') && this.defaultData.hasOwnProperty('nroDoc') && !isEmpty(this.$route.params.tipodoc) && !isEmpty(this.$route.params.numerodoc)) {
          this.test = {document: this.$route.params.tipodoc, number: this.$route.params.numerodoc}
          this.formulario_verificar_cliente.identity = {
            document: this.$route.params.tipodoc,
            number: this.$route.params.numerodoc
          };
            this.$store.dispatch('product/setFormProduct', {verify_route: true});
          this.handleSave();
        } else {
          this.showForm = true;
        }
      }
    }
  }
}
</script>
